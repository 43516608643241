<template>
  <v-card flat>
    <v-card-title>
      <div>
        <div class="headline">Invoices</div>
        <span class="subtitle-1 light-grey"
          >All invoices sent from Make Influence</span
        >
      </div>
    </v-card-title>
    <v-card-text>
      <invoice-data-table
        :loading="loading"
        :items="invoices"
      ></invoice-data-table>

      <div class="text-center">
        <v-btn
          v-if="!loading && hasMore"
          text
          small
          color="primary"
          @click="loadMoreInvoices()"
          >Show more</v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers";
import InvoiceDataTable from "../../../data-tables/InvoiceDataTable";
import { mapActions } from "vuex";

export default {
  components: { InvoiceDataTable },
  filters: { local_numbers },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    invoices: [],
    dailyStats: [],
    loading: true
  }),
  computed: {},
  methods: {
    ...mapActions("core", ["getInvoices"]),
    loadMoreInvoices() {
      this.loading = true;
      // Request params
      let params = {
        limit: 10,
        user_id: this.value.user.uuid
      };
      if (this.invoices.length > 0) {
        let lastIndex = this.invoices.length - 1;
        let lastInvoice = this.invoices[lastIndex];
        params.starting_after = lastInvoice.stripe_key;
      }
      this.getInvoices(params).then(
        response => {
          this.loading = false;
          if (response.length !== params.limit) {
            this.hasMore = false;
          }
          for (let index in response) {
            this.invoices.push(response[index]);
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  watch: {},
  created() {
    this.loadMoreInvoices();
  }
};
</script>
