<template>
  <div>
    <v-card flat>
      <v-card-title>
        <div>
          <div class="headline">Campaigns</div>
          <!--<span class="subtitle-1 grey&#45;&#45;text">Oversigt over nuværende måned</span>-->
        </div>
        <v-spacer></v-spacer>

        <v-btn
          :disabled="!value.pixel_approved_at"
          color="primary"
          @click="newCampaign()"
        >
          <v-icon small left>fal fa-plus</v-icon> Create campaign
        </v-btn>
      </v-card-title>

      <campaigns-tabs
        vertical
        :grow="false"
        class="elevation-0"
        :business="value"
      ></campaigns-tabs>
    </v-card>
  </div>
</template>
<script>
import CampaignsTabs from "../../../Tabs/CampaignsTabs/CampaignsTabs";
import { mapActions } from "vuex";

export default {
  components: { CampaignsTabs },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    activeCampaigns: null
  }),
  methods: {
    ...mapActions("core/campaigns", ["createCampaign"]),
    newCampaign() {
      this.createCampaign({ business_uuid: this.value.uuid }).then(
        createCampaign => {
          this.$router.push({
            name: "campaign.create",
            params: { id: createCampaign.uuid }
          });
        },
        error => {
          console.log(error);
          this.setSnackError("Something went wrong.");
        }
      );
    }
  }
};
</script>
