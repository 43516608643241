<template>
  <div v-if="business.user">
    <v-alert
      class="mb-3"
      type="error"
      :value="business && !business.pixel_approved_at"
    >
      The brand does not have approved pixel. This
      <u>has</u> to be approved by Make Influence.
    </v-alert>

    <v-card>
      <v-card-text class="grey lighten-4">
        <v-container fluid grid-list-xl class="p-0">
          <v-layout wrap>
            <v-flex xs12 sm12 md3 class="text-center align-self-center">
              <v-avatar :size="170" color="primary">
                <span class="font-weight-bold display-1 white--text">
                  {{ business.display_name | first_letter }}
                </span>
              </v-avatar>
            </v-flex>
            <v-flex xs12 sm12 md9>
              <div>
                <div class="headline font-weight-bold primary--text">
                  {{ business.display_name }}
                </div>
                <div class="primary--text">
                  {{ business.user.first_name + " " + business.user.last_name }}
                </div>
              </div>

              <v-layout wrap>
                <v-flex sm6 xs12 class="p-0">
                  <v-list color="transparent" two-line dense class="p-0">
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>fal fa-envelope</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <a
                            :href="'mailto:' + business.user.email"
                            target="_blank"
                          >
                            {{ business.user.email }}
                          </a>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="business.phone">
                      <v-list-item-action>
                        <v-icon>fal fa-phone</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <a :href="'tel:' + business.phone" target="_blank">
                            {{ business.phone }}
                          </a>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="businessWebsite">
                      <v-list-item-action>
                        <v-icon>fal fa-globe</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <a :href="businessWebsite" target="_blank">
                            {{ business.website }}
                          </a>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex sm6 xs12 class="p-0">
                  <v-list color="transparent" two-line dense class="p-0">
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>fal fa-building</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ business.company }} - VAT
                          {{ business.vat }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action>
                        <v-icon
                          v-if="business.pixel_approved_at"
                          color="success"
                          small
                          >fal fa-check</v-icon
                        >
                        <v-icon v-else color="error" small>fal fa-times</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Tracking code
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action>
                        <v-icon
                          v-if="business.promotion_code_tracking_approved_at"
                          color="success"
                          small
                          >fal fa-check</v-icon
                        >
                        <v-icon v-else color="error" small>fal fa-times</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Promotion code tracking
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <business-tabs v-model="business"></business-tabs>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import BusinessTabs from "../Tabs/BusinessTabs/BusinessTabs";
import first_letter from "@/helpers/filters/first_letter";

export default {
  components: {
    BusinessTabs
  },
  filters: { first_letter },
  data: () => ({
    createBusinessDialog: false,
    business: {}
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    businessWebsite() {
      if (!this.business) {
        return null;
      }

      return this.business.website.indexOf("http") === -1
        ? "//" + this.business.website
        : this.business.website;
    }
  },
  methods: {
    ...mapActions("core", ["getBusiness"]),
    getBusinessItem() {
      const uuid = this.$route.params.id;
      const params = {
        business_uuid: uuid
      };
      this.getBusiness(params).then(
        business => {
          this.business = business;
        },
        () => {
          this.setSnackError("Something went wrong.");
        }
      );
    }
  },
  created() {
    this.getBusinessItem();
  }
};
</script>
