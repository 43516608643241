var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":"","items-per-page":10000},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(_vm._f("utc_to_local_datetime")(item.date,"DD-MM-YYYY")))]),_c('td',[_vm._v(_vm._s(item.invoice_key))]),_c('td',{staticClass:"text-right"},[_vm._v("DKK "+_vm._s(_vm._f("local_numbers")(item.total)))]),_c('td',[_vm._v(" "+_vm._s(item.status)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.type === "INVOICE" ? "Invoice" : "Credit note")+" ")]),_c('td',{staticClass:"word-no-wrap"},[_c('v-tooltip',{attrs:{"disabled":_vm.$vuetify.breakpoint.xsOnly,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","outlined":"","color":"primary","target":"_blank","href":item.download_url}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-file-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download")])]),(item.type === 'INVOICE')?_c('v-tooltip',{attrs:{"disabled":_vm.$vuetify.breakpoint.xsOnly,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":"","outlined":"","color":"primary","target":"_blank","href":item.view_url}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-file-invoice-dollar")])],1)]}}],null,true)},[_c('span',[_vm._v("Payment link")])]):_vm._e(),(item.type === 'INVOICE')?_c('v-tooltip',{attrs:{"disabled":_vm.$vuetify.breakpoint.xsOnly,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":"","outlined":"","color":"primary","to":{
                  name: 'report.sales',
                  query: {
                    invoice_nr: item.invoice_key,
                    start_date: '2018-01-01',
                    end_date: '2030-12-31'
                  }
                }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-file-search")])],1)]}}],null,true)},[_c('span',[_vm._v("See sales")])]):_vm._e()],1)])}),0)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"}),_c('div',{staticClass:"text-center",attrs:{"slot":"no-data"},slot:"no-data"},[_vm._v(" No results ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }