<template>
  <v-tabs
    v-if="business"
    v-model="selectedTab"
    grow
    icons-and-text
    background-color="grey lighten-4"
  >
    <v-tabs-slider color="accent"></v-tabs-slider>

    <template v-for="tab in tabs">
      <v-tab class="accent--text" :key="tab.name">
        {{ tab.label }}
        <v-icon color="accent">{{ tab.icon }}</v-icon>
      </v-tab>
    </template>

    <v-tabs-items v-model="selectedTab" touchless>
      <template v-for="tab in tabs">
        <v-tab-item :key="'tab-' + tab.name">
          <campaigns-tab
            v-if="tab.name === 'campaigns'"
            v-model="business"
          ></campaigns-tab>
          <stats-tab
            v-else-if="tab.name === 'stats'"
            v-model="business"
          ></stats-tab>
          <invoices-tab
            v-else-if="tab.name === 'invoices'"
            v-model="business"
          ></invoices-tab>

          <v-card v-else flat>
            <v-card-text>
              <v-alert :value="true" type="warning">Under construction</v-alert>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </template>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import CampaignsTab from "./CampaignsTab";
import StatsTab from "./StatsTab";
import InvoicesTab from "./InvoicesTab";
export default {
  components: { CampaignsTab, StatsTab, InvoicesTab },
  props: {
    value: {
      default: null
    }
  },
  data: () => ({
    selectedTab: null,
    tabs: [
      {
        name: "campaigns",
        label: "Campaigns",
        icon: "fal fa-bullhorn"
      },
      {
        name: "stats",
        label: "Performance",
        icon: "fal fa-chart-area"
      },
      {
        name: "invoices",
        label: "Invoice",
        icon: "fal fa-receipt"
      }
    ]
  }),
  computed: {
    business: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
