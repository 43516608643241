<template>
  <v-card flat>
    <v-card-title>
      <div>
        <div class="headline">Performance</div>
        <div class="subtitle-1 light-grey">Overview of current month</div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-container fluid grid-list-xl class="p-0">
        <v-layout wrap>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-hand-point-up"
              :title="$options.filters.local_numbers(stats.clicks)"
              subtitle="Click"
              flat
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-fingerprint"
              :title="$options.filters.local_numbers(stats.unique_clicks)"
              subtitle="Unique clicks"
              flat
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-shopping-basket"
              :title="$options.filters.local_numbers(stats.sales)"
              subtitle="Sales"
              flat
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-percentage"
              :title="$options.filters.local_numbers(stats.conversion) + '%'"
              subtitle="Conversion"
              flat
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-coins"
              :title="$options.filters.local_numbers(stats.value)"
              subtitle="Revenue"
              flat
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-money-bill-wave"
              :title="$options.filters.local_numbers(stats.commission)"
              subtitle="Commission"
              flat
              bordered
            ></stat-widget>
          </v-flex>
          <!--<v-flex xs12>-->
          <!--<div class="subheading mt-3">Daglig præstation</div>-->
          <!--<daily-stats-data-table :loading="loading" :items="dailyStats"></daily-stats-data-table>-->
          <!--</v-flex>-->
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import StatWidget from "../../../widgets/StatWidget";
import local_numbers from "@/helpers/filters/local_numbers";
import { mapActions } from "vuex";

export default {
  components: { StatWidget },
  filters: { local_numbers },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    stats: {},
    dailyStats: [],
    loading: true
  }),
  computed: {},
  methods: {
    ...mapActions("core", ["getStats"]),
    getBusinessStats() {
      let params = {
        start_date: this.$moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        end_date: this.$moment().format("YYYY-MM-DD"),
        starting_at: this.$moment().format("YYYY-MM-DD hh:mm:ss"),
        ending_at: this.$moment().format("YYYY-MM-DD hh:mm:ss")
      };
      if (this.isAgency) {
        params.business_uuid = this.value.uuid;
      }
      this.getStats(params).then(
        response => {
          this.stats = response[0];
          this.isLoading = false;
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  watch: {},
  created() {
    this.getBusinessStats();
  }
};
</script>
